import React from 'react';
import { getUser, removeUserSession } from '../Utils/Common';
import { createBrowserHistory } from "history";
import { Route, Redirect } from 'react-router-dom'

import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import styled, { css } from 'styled-components'

const hist = createBrowserHistory();

const  FallbackContainer = styled.div`
position : Fixed;
top:50%;
left:50%;
transform:translate(-50%,-50%);
z-index:999999999;
opacity :15; 
transition: '0.5s filter linear',
filter: 'grayscale(0.5) blur(50px)',
`

export default class LogOutFun extends React.Component {
  
state = {
  LogOutPage: [],
}
componentDidMount() { 
debugger;
this.LogOutPage();
}
componentWillUnmount() {debugger;
  this.LogOutPage();
  }


  LogOutPage = async () => {
    debugger;
    const user = getUser();
    
    removeUserSession();
    //return <Redirect to="/" />
    window.location.href = "/"
    //hist.push('/');
  }
  render() {
    <FallbackContainer>
      <Loader 
        type="Puff"
        color="#195e5e"
        height={100}
        width={100}
        timeout={500} //3 secs
        
      />
      </FallbackContainer>
    return <login />
  }
}




//export default Dashboard;