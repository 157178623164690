import logo from './logo.svg';
import './App.css';
// import { BrowserRouter, Switch, Route, NavLink } from 'react-router-dom';
// import { Router, Route, Link, browserHistory, IndexRoute } from 'react-router'
// import Login from './Login';import React from 'react';

import React, { Component } from 'react';
import Routers from './Router';
import { useHistory } from "react-router-dom";
import { Router, browserHistory, Route, Link } from 'react-router';

class App extends Component {

  render() {
    
    return (
     <Routers  />
    );
  
}
}

export default App;
// function App() {
//   return (
//     <div className="App">
//      <BrowserRouter>
//         <div>
//           <div className="header">
//             <NavLink activeClassName="active" to="/login">Login</NavLink>         
//           </div>
//           <div className="content">
//             <Switch>     
//               <Route path="/login" component={Login} />            
//             </Switch>
//           </div>
//         </div>
//       </BrowserRouter>
//     </div>
//   );
// }

// export default App;
