
import React from "react";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import { countApiUrl, getUser, removeUserSession } from '../Utils/Common';

import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import styled, { css } from 'styled-components'

// reactstrap components
import { Card, CardHeader, CardBody, CardFooter, CardTitle, Row, Col, UncontrolledDropdown, DropdownToggle,  DropdownMenu, DropdownItem, Table, Button, Label, FormGroup, Input, UncontrolledTooltip,} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

import { dashboardPanelChart, dashboardShippedProductsChart, dashboardAllProductsChart, dashboard24HoursPerformanceChart,} from "variables/charts.js";
import axios from 'axios';
import { apiUrl, setUserSession } from '../Utils/Common';





import FixedPlugin from "components/FixedPlugin/FixedPlugin";


const RegistrationApi = apiUrl();

class Registration extends React.Component {
    constructor(props) {
      super(props);
      this.state = { name1: '',name2: '',name3: '',name4: '',name5: '',name6: '',name7: '' };
      this.state = {
        ComboTitleMaster: [
        // { name: 'One', id: 1 },
        // { name: 'Two', id: 2 },
        // { name: 'Three', id: 3 },
        // { name: 'four', id: 4 }
      ]
    };
    }
    componentDidMount() { this.TitleMaster(); }
    TitleMaster = async () => {
      debugger;
      try { //try to get data
        var url = RegistrationApi+'TitleMasterRecord';
          const response = await fetch(url);
          if (response.ok) { // ckeck if status code is 200
            debugger;
              const data = await response.json();
              var a = data;
              this.setState({ ComboTitleMaster: data});
            
          } else { this.setState({ error: true }) }
      } catch (e) { //code will jump here if there is a network problem
  this.setState({ error: true });
  }};



    handleChange = (event) => {
      debugger;
      this.setState({[event.target.name]: event.target.value});
    } 
    handleSubmit = (event) => {
      debugger;
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(this.state)
    };
      fetch( RegistrationApi+'RegisationForm', requestOptions)
      .then(function(response) {
          console.log(response)
          return response.json();
        });  
      event.preventDefault();
  } 
  
    render() {
      let optionTemplate = this.state.ComboTitleMaster.map(v => (
        <option value={v.id}>{v.pTitle}</option>
      ));
      return (
        <form onSubmit={this.handleSubmit}>   <div className="content">
        <div className="MaintainHeight">
        <Row>
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <h5 className="card-category">Registration Form</h5>
                </CardHeader>
                <CardBody>
              
            <Col xs={12} md={6} className="Left">
            Name1:
            <input type="text" value={this.state.value} name="name1" onChange={this.handleChange} />
            </Col>
            <Col xs={12} md={6} className="Right">
            Name2:
            <input type="text" value={this.state.value} name="name2" onChange={this.handleChange} />
            </Col> 
            <Col xs={12} md={6} className="Left">
            Name3:
            <input type="text" value={this.state.value} name="name3" onChange={this.handleChange} />
            </Col>
            <Col xs={12} md={6} className="Right">
            Name4:
            <input type="text" value={this.state.value} name="name4" onChange={this.handleChange} />
            </Col> 
            <Col xs={12} md={6} className="Left">
            Name5:
            <input type="text" value={this.state.value} name="name5" onChange={this.handleChange} />
            </Col>
            <Col xs={12} md={6} className="Left">
            Name6:
            <input type="text" value={this.state.value} name="name6" onChange={this.handleChange} />
            </Col>
            <Col xs={12} md={6} className="Left">
            Name7:
            <input type="text" value={this.state.value} name="name7" onChange={this.handleChange} />
            </Col>
            
           
            <Col xs={12} md={6} className="Right">
            <select value={this.state.ComboTitleMaster} onChange={this.handleChange}>
          {optionTemplate}
        </select>
          <input type="submit" value="Submit" className="Right" />
          </Col>
                </CardBody>
              </Card>
            </Col>
            </Row>
          </div>  </div>
           </form>
      );
    }
  }
  export default Registration