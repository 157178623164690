
import React from "react";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import { countApiUrl, getUser, removeUserSession } from '../Utils/Common';

import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import styled, { css } from 'styled-components'
const columnHeader =["Doctor's","Addmission","Consultation","Occupancy"];
const columnHeaderParticulars =["Particulars","Today","FTM",];


const MonthWiseCollection =["Date","Revenue","Collection",];

const DayWiseCollection =["Date","Revenue","Collection",];

// reactstrap components
import { Card, CardHeader, CardBody, CardFooter, CardTitle, Row, Col, UncontrolledDropdown, DropdownToggle,  DropdownMenu, DropdownItem, Table, Button, Label, FormGroup, Input, UncontrolledTooltip,} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

import { dashboardPanelChart, dashboardShippedProductsChart, dashboardAllProductsChart, dashboard24HoursPerformanceChart,} from "variables/charts.js";
import axios from 'axios';
import { apiUrl, setUserSession } from '../Utils/Common';

import Moment from 'moment';
import NumberFormat from 'react-number-format';



import FixedPlugin from "components/FixedPlugin/FixedPlugin";
import ReactTooltip from "react-tooltip";

let RegTotal = "0";
let ConTotal = "0";
let AdmsTotal = "0";

const countDashboardApi = apiUrl();
export  function hexToRGB(hex, alpha) {
  var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

const chartColor = "#FFFFFF";

const  FallbackContainer = styled.div`
position : Fixed;
top:50%;
left:50%;
transform:translate(-50%,-50%);
z-index:999999999;
opacity :15; 
transition: '0.5s filter linear',
filter: 'grayscale(0.5) blur(50px)',
`
const numberFormat = (value) =>
  new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR'
  }).format(value);
export default class Dashboard extends React.Component {
  state = {
  
    userList: [], // list is empty in the beginning
    OccupancyData: {},
    RegistrationData: {},
    ConsultationData: {},
    AdmissionData: {},

    DoctorSummaryTableData: {},
    ParticularsTableData: {},
    GETFinancialYearReport: {},
    FinancialYearReport: {},

    error: false,
    

 };

  componentDidMount() {

    this.getUserList(); // function call
    this.DashBoardAllDataforDisplayChart();

    this.DoctorSummaryData();
    this.ParticularsData();
    this.GETFinancialYearReportData();
    this.FinancialYearReportData();
    
    
   
    this.generateHeader();
    this.generateHeadercolumnHeaderParticulars();
    this.generateHeadercolumnMonthWiseCollection();
    this.generateHeadercolumnDayWiseCollection();


    this.generateTableData();   
    this.generateTableDataParticulars();
    this.generateTableDataMonthWiseCollection();
    this.generateTableDataDayWiseCollection();


   // this.generateHeader = this.generateHeader.bind(this);
  //this.generateTableData = this.generateTableData.bind(this);
  }


  
 



componentWillUnmount() {
  //this.getUserList(); // function call
  }
  getUserList = async () => {
    try { //try to get data
      var url = countDashboardApi + 'TotalCounts';
        const response = await fetch(url);
        if (response.ok) { // ckeck if status code is 200
        
            const data = await response.json();
            var a = data;
            this.setState({ userList: data});
          
        } else { this.setState({ error: true }) }
    } catch (e) { //code will jump here if there is a network problem
this.setState({ error: true });
}};


ParticularsData = async () => {
  const ParticularsDataApi = apiUrl();
  try { //try to get data
    var url =  ParticularsDataApi + 'Particulars';
      const response = await fetch(url);
      if (response.ok) { // ckeck if status code is 200
       
          const data = await response.json();
          var a = data;
        this.setState({ ParticularsTableData: data })
      } else { this.setState({ error: true }) }
  } catch (e) { //code will jump here if there is a network problem
this.setState({ error: true });
}};




DoctorSummaryData = async () => {
  const DoctorSummaryDataApi = apiUrl();
  try { //try to get data
    var url = DoctorSummaryDataApi + 'DoctorSummary';
      const response = await fetch(url);
      if (response.ok) { // ckeck if status code is 200
       
          const data = await response.json();
          var a = data;
        this.setState({ DoctorSummaryTableData: data })
      } else { this.setState({ error: true }) }
  } catch (e) { //code will jump here if there is a network problem
this.setState({ error: true });
}};


GETFinancialYearReportData = async () => {

  const GETFinancialYearReportDataApi = apiUrl();
  try { //try to get data
    var url = GETFinancialYearReportDataApi + 'GETFinancialYearReport';
      const response = await fetch(url);
      if (response.ok) { // ckeck if status code is 200
       
          const data = await response.json();
          var a = data;
        this.setState({ GETFinancialYearReport: data })
      } else { this.setState({ error: true }) }
  } catch (e) { //code will jump here if there is a network problem
this.setState({ error: true });
}};





FinancialYearReportData = async () => {
  
  const FinancialYearReportDataApi = apiUrl();
  try { //try to get data
    var url = FinancialYearReportDataApi + 'FinancialYearReport';
      const response = await fetch(url);
      if (response.ok) { // ckeck if status code is 200
       
          const data = await response.json();
          var a = data;
        this.setState({ FinancialYearReport: data })
        console.log(this.state.FinancialYearReport)
      } else { this.setState({ error: true }) }
  } catch (e) { //code will jump here if there is a network problem
this.setState({ error: true });
}};







generateHeader(){
  let res=[];
  var imgsrc="";
  var c="";
  var msg="";
  for(var i =0; i < columnHeader.length; i++){
    debugger;
    if(i==3) { imgsrc="../occ.png";msg="Occupancy"}if(i==2) { imgsrc="../admitted.png";msg="Admission"}if(i==1) { imgsrc="../cons.png";msg="Consultation"}if(i==0) { imgsrc="../consult.png";msg="Doctor"}
    if(i>0)
    { 
      c="rightalign";
    }
    res.push(<th key={columnHeader[i]} data-tip={msg} data-for='toolTip1' data-place='top' className={c}>{<img src={imgsrc} style={{height:"25px"}} alt="logo"/>}</th>)
      // res.push(<th key={columnHeader[i]}  className={c}>{columnHeader[i]}</th>)
  }
  return res;
}

generateTableData(){
  let res=[];
      let tableData = this.state.DoctorSummaryTableData;
      for(var i =0; i < tableData.length; i++){
          res.push(
          <tr>
          <td>{tableData[i].doctorName}</td>
          {/* <td>{tableData[i].drcode}</td>
          <td>{tableData[i].convisible}</td>
          <td>{tableData[i].addvisible}</td>
          <td>{tableData[i].occvisible}</td> */}
          <td style={{textAlign:"right"}}>{tableData[i].addmission}</td>
          <td style={{textAlign:"right"}}>{tableData[i].consultation}</td>
          <td style={{textAlign:"right"}}>{tableData[i].occupancy}</td>
          </tr>
          )
      }     
      return res;
}




generateHeadercolumnHeaderParticulars(){
  let res=[];
  var c="";
  for(var i =0; i < columnHeaderParticulars.length; i++){
    if(i>0)
    { 
      c="rightalign";
    }
      res.push(<th key={columnHeaderParticulars[i]}  className={c}>{columnHeaderParticulars[i]}</th>)
  }
  return res;
}
generateTableDataParticulars(){
  let res=[];
   
      let tableData = this.state.ParticularsTableData;
      for(var i =0; i < tableData.length; i++){
          res.push(
          <tr>
          <td>{tableData[i].particular}</td>
          <td style={{textAlign:"right"}}>{
         parseFloat(tableData[i].today).toFixed(2)}
          </td>
          <td style={{textAlign:"right"}}>{
          parseFloat(tableData[i].month).toFixed(2)
          }</td>
          </tr>
          )
      }     
      return res;
}


generateHeadercolumnMonthWiseCollection(){
  let res=[];
  var c="";
  for(var i =0; i < MonthWiseCollection.length; i++){
    if(i>0)
    { 
      c="rightalign";
    }
      res.push(<th key={MonthWiseCollection[i]}  className={c}>{MonthWiseCollection[i]}</th>)
  }
  return res;
}
generateTableDataMonthWiseCollection(){
  let res=[];
   
      let tableData = this.state.GETFinancialYearReport;
      for(var i =0; i < tableData.length; i++){
          res.push(
          <tr>
          {/* <td>{tableData[i].eDate}</td> */}
          <td>{Moment(tableData[i].eDate).format('DD-MMM-yyyy')}</td>
          <td style={{textAlign:"right"}}>{
         numberFormat( parseFloat(tableData[i].revenue).toFixed(2))
          }</td>
          <td style={{textAlign:"right"}}>{
        numberFormat(parseFloat(tableData[i].hospitalCollection).toFixed(2))
          }</td>
          </tr>
          )
      }     
      return res;
}




generateHeadercolumnDayWiseCollection(){
  let res=[];
  var c="";
  for(var i =0; i < DayWiseCollection.length; i++){
    if(i>0)
    { 
      c="rightalign";
    }
      res.push(<th key={DayWiseCollection[i]}   className={c}>{DayWiseCollection[i]}</th>)
  }
  return res;
}
generateTableDataDayWiseCollection(){
  let res=[];
   
      let tableData = this.state.FinancialYearReport;
      for(var i =0; i < tableData.length; i++){
          res.push(
          <tr>
             <td>{Moment(tableData[i].eDate).format('DD-MMM-yyyy')}</td>
          {/* <td>{tableData[i].eDate}</td> */}
          <td style={{textAlign:"right"}}>{
           numberFormat(parseFloat(tableData[i].revenue).toFixed(2))
          }</td>


          
          <td style={{textAlign:"right"}}>{
           numberFormat(parseFloat(tableData[i].hospitalCollection).toFixed(2))
        }</td>
          </tr>
          )
      }     
      return res;
}



DashBoardAllDataforDisplayChart = async () => {
   
  
      var canvas = document.createElement("CANVAS");
      const ctx = canvas.getContext("2d");
      var chartColor = "#FFFFFF";
      var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
      gradientStroke.addColorStop(0, "#80b6f4");
      gradientStroke.addColorStop(1, chartColor);
      var gradientFill = ctx.createLinearGradient(0, 200, 0, 50);
      gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
      gradientFill.addColorStop(1, "rgba(255, 255, 255, 0.14)");
  
      
  
  
        var url = countDashboardApi + 'DashBoardchart';
        const response = await fetch(url);
        if (response.ok) { // ckeck if status code is 200
    
            var Result = await response.json();
            
             let OccupancyDates = [];  
             let OccupancyCounts = [];  
            Result.occupancyResult.forEach(record => {  
              OccupancyDates.push(record.month);  
              OccupancyCounts.push(record.count);  
            }); 
            this.OccupancyChart(OccupancyDates,OccupancyCounts);
            
            let RegistrationDates = [];  
            let RegistrationCounts = [];  
            Result.consultationResult.forEach(record => {  
              RegistrationDates.push(record.month);  
              RegistrationCounts.push(record.count);  
            }); 
           this.RegistrationChart(RegistrationDates,RegistrationCounts);
            
            let ConsultationDates = [];  
            let ConsultationCounts = [];  
            Result.consultationResult.forEach(record => {  
              ConsultationDates.push(record.month);  
              ConsultationCounts.push(record.count);  
            }); 
           this.ConsultationChart(ConsultationDates,ConsultationCounts);

            let AdmisssionDates = [];  
            let AdmisssionCounts = [];  
            Result.admissionResult.forEach(record => {  
              AdmisssionDates.push(record.month);  
              AdmisssionCounts.push(record.count);  
            }); 
           this.AdmissionChart(AdmisssionDates,AdmisssionCounts);

        }
         else { this.setState({ error: true }) }
  };

// ##############################
// // // Dashboard view - Occupancy  Chart
// #############################
OccupancyChart = async (OccupancyDates,OccupancyCounts) => {
 

    var canvas = document.createElement("CANVAS");
    const ctx = canvas.getContext("2d");
    var chartColor = "#FFFFFF";
    var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
    gradientStroke.addColorStop(0, "#80b6f4");
    gradientStroke.addColorStop(1, chartColor);
    var gradientFill = ctx.createLinearGradient(0, 200, 0, 50);
    gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
    gradientFill.addColorStop(1, "rgba(255, 255, 255, 0.14)");

    


      
     
          this.setState({  
            OccupancyData: {  
                    labels: OccupancyDates,  
                    datasets: [  
                            {  
                              label: "Occupancy",
                                    
                                    borderColor: chartColor,
                                    pointBorderColor: chartColor,
                                    pointBackgroundColor: "#2c2c2c",
                                    pointHoverBackgroundColor: "#2c2c2c",
                                    pointHoverBorderColor: chartColor,
                                    pointBorderWidth: 1,
                                    pointHoverRadius: 7,
                                    pointHoverBorderWidth: 2,
                                    pointRadius: 5,
                                    fill: true,
                                    backgroundColor: gradientFill,
                                    borderWidth: 2,
                                    data: OccupancyCounts,  
                            }  
                    ]  
            } ,   
    });  
    
  }
// ##############################
// // // Dashboard view - Registration Chart
// #############################
RegistrationChart = async (RegistrationDates,RegistrationCounts) => {
   
  
      var canvas = document.createElement("CANVAS");
    var ctx = canvas.getContext("2d");
    var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
    gradientStroke.addColorStop(0, "#80b6f4");
    gradientStroke.addColorStop(1, chartColor);
    var gradientFill = ctx.createLinearGradient(0, 170, 0, 50);
    gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
    gradientFill.addColorStop(1, "rgba(249, 99, 59, 0.40)");
  
      


      
          this.setState({  
           RegistrationData: {  
                    labels: RegistrationDates,  
                    datasets: [  
                            {  
                              label: "Registration",
                            borderColor: "#f96332",
          pointBorderColor: "#FFF",
          pointBackgroundColor: "#f96332",
          pointBorderWidth: 2,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 1,
          pointRadius: 4,
          fill: true,
          backgroundColor: gradientFill,
          borderWidth: 2,
          data: RegistrationCounts,  
                            }  
                    ]  
            } , 
    });  
      
  };
// ##############################
// // // Dashboard view - Consultation Chart
// #############################
ConsultationChart = async (ConsultationDates,ConsultationCounts) => {
   
  

  var canvas = document.createElement("CANVAS");
  var ctx = canvas.getContext("2d");
  var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
  gradientStroke.addColorStop(0, "#18ce0f");
  gradientStroke.addColorStop(1, chartColor);
  var gradientFill = ctx.createLinearGradient(0, 170, 0, 50);
  gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
  gradientFill.addColorStop(1, hexToRGB("#18ce0f", 0.4));



    this.setState({  
      ConsultationData: {  
              labels: ConsultationDates,  
              datasets: [  
                      {  
                        label: "Consultation",
                        borderColor: "#18ce0f",
                        pointBorderColor: "#FFF",
                        pointBackgroundColor: "#18ce0f",
                        pointBorderWidth: 2,
                        pointHoverRadius: 4,
                        pointHoverBorderWidth: 1,
                        pointRadius: 4,
                        fill: true,
                        backgroundColor: gradientFill,
                        borderWidth: 2,
                        data: ConsultationCounts,  
                      }  
              ]  
      } , 
});  

};
// ##############################
// // // Dashboard view - Admission Chart
// #############################
AdmissionChart = async (AdmisssionDates,AdmisssionCounts) => {



  var canvas = document.createElement("CANVAS");
  var ctx = canvas.getContext("2d");
  var gradientFill = ctx.createLinearGradient(0, 170, 0, 50);
  gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
  gradientFill.addColorStop(1, hexToRGB("#2CA8FF", 0.6));




    this.setState({  
      AdmissionData: {  
              labels: AdmisssionDates,  
              datasets: [  
                      {  
                        label: "Admission",
                              backgroundColor: gradientFill,
          borderColor: "#2CA8FF",
          pointBorderColor: "#FFF",
          pointBackgroundColor: "#2CA8FF",
          pointBorderWidth: 2,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 1,
          pointRadius: 4,
          fill: true,
          borderWidth: 1,
          data: AdmisssionCounts,  
                      }  
              ]  
      } , 
});  
}



  render() {  
     const { userList, error } = this.state    
  
    return (
      <>
      <ReactTooltip id="toolTip1" />
      <FallbackContainer>
      <Loader 
        type="Puff"
        color="#195e5e"
        height={100}
        width={100}
        timeout={500} //3 secs
        
      />
      </FallbackContainer>



        <PanelHeader
          size="lg"
          content={
            <Line  
            data={this.state.OccupancyData}  
            options={

               {
                layout: {
                  padding: {
                    left: 20,
                    right: 20,
                    top: 0,
                    bottom: 0,
                  },
                },
                maintainAspectRatio: false,
                tooltips: {
                  backgroundColor: "#fff",
                  titleFontColor: "#333",
                  bodyFontColor: "#666",
                  bodySpacing: 4,
                  xPadding: 12,
                  mode: "nearest",
                  intersect: 0,
                  position: "nearest",
                },
                legend: {
                  position: "bottom",
                  fillStyle: "#FFF",
                  display: false,
                },
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        fontColor: "rgba(255,255,255,0.4)",
                        fontStyle: "bold",
                        beginAtZero: true,
                        maxTicksLimit: 5,
                        padding: 10,
                      },
                      gridLines: {
                        drawTicks: true,
                        drawBorder: false,
                        display: true,
                        color: "rgba(255,255,255,0.1)",
                        zeroLineColor: "transparent",
                      },
                    },
                  ],
                  xAxes: [
                    {
                      gridLines: {
                        display: false,
                        color: "rgba(255,255,255,0.1)",
                      },
                      ticks: {
                        padding: 10,
                        fontColor: "rgba(255,255,255,0.4)",
                        fontStyle: "bold",
                      },
                    },
                  ],
                },
               }

            } />
        // <Line
        //   data={dashboardPanelChart.data}
        //   options={dashboardPanelChart.options}
        // />
      }
    />
          
          
        
        <div className="content">
        {userList.length > 0 && userList.map(user => (                 
                  //  <div key={user}>                     
                  //      <div>
                  //          <div>{user.registrationCount}</div>
                  //          <div>{user.consultationCount}</div>
                  //          <div>{user.admissionCount}</div>
                  //      </div>
                  //  </div>                
          <Row key={user}>         
            <Col xs={12} md={4}>
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Global Status</h5>
                  <CardTitle tag="h4">Registration  Total
                  </CardTitle>
                  <CardTitle tag="h4" style={{fontWeight: "bold", fontSize: 24}}>
                  {user.registrationCount}
                    </CardTitle>                 
                  {/* <UncontrolledDropdown>
                    <DropdownToggle
                      className="btn-round btn-outline-default btn-icon"
                      color="default"
                    >
                      <i className="now-ui-icons loader_gear" />
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem>Action</DropdownItem>
                      <DropdownItem>Another Action</DropdownItem>
                      <DropdownItem>Something else here</DropdownItem>
                      <DropdownItem className="text-danger">
                        Remove data
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown> */}
                </CardHeader>
                <CardBody>
                  <div className="chart-area">
                  <Line  
            data={this.state.RegistrationData}  
            options={{
              maintainAspectRatio: false,
              legend: {
                display: false,
              },
              tooltips: {
                bodySpacing: 4,
                mode: "nearest",
                intersect: 0,
                position: "nearest",
                xPadding: 10,
                yPadding: 10,
                caretPadding: 10,
              },
              responsive: 1,
              scales: {
                yAxes: [
                  {
                    display: 0,
                    ticks: {
                      display: false,
                      maxTicksLimit: 7,
                    },
                    gridLines: {
                      zeroLineColor: "transparent",
                      drawTicks: false,
                      display: false,
                      drawBorder: false,
                    },
                  },
                ],
                xAxes: [
                  {
                    display: 0,
                    ticks: {
                      display: false,
                    },
                    gridLines: {
                      zeroLineColor: "transparent",
                      drawTicks: false,
                      display: false,
                      drawBorder: false,
                    },
                  },
                ],
              },
              layout: {
                padding: { left: 0, right: 0, top: 15, bottom: 15 },
              },
            }}
            />
                    {/* <Line
                      data={dashboardShippedProductsChart.data}
                      options={dashboardShippedProductsChart.options}
                    /> */}
                  </div>
                </CardBody>
                {/* <CardFooter>
                  <div className="stats">
                    <i className="now-ui-icons arrows-1_refresh-69" /> Just
                    Updated
                  </div>
                </CardFooter> */}
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Global Status</h5>
                  <CardTitle tag="h4">Consultation Total
                 </CardTitle>
                  <CardTitle tag="h4" style={{fontWeight: "bold", fontSize: 24}}>
                    {user.consultationCount}
                  
                    </CardTitle>
                  {/* <UncontrolledDropdown>
                    <DropdownToggle
                      className="btn-round btn-outline-default btn-icon"
                      color="default"
                    >
                      <i className="now-ui-icons loader_gear" />
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem>Action</DropdownItem>
                      <DropdownItem>Another Action</DropdownItem>
                      <DropdownItem>Something else here</DropdownItem>
                      <DropdownItem className="text-danger">
                        Remove data
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown> */}
                </CardHeader>
                <CardBody>
                  <div className="chart-area">

                  <Line  
            data={this.state.ConsultationData}  
            options={{
              maintainAspectRatio: false,
              legend: {
                display: false,
              },
              tooltips: {
                bodySpacing: 4,
                mode: "nearest",
                intersect: 0,
                position: "nearest",
                xPadding: 10,
                yPadding: 10,
                caretPadding: 10,
              },
              responsive: 1,
              scales: {
                yAxes: [
                  {
                    gridLines: {
                      zeroLineColor: "transparent",
                      drawBorder: false,
                    },
                    ticks: {
                      maxTicksLimit: 7,
                    },
                  },
                ],
                xAxes: [
                  {
                    display: 0,
                    ticks: {
                      display: false,
                    },
                    gridLines: {
                      zeroLineColor: "transparent",
                      drawTicks: false,
                      display: false,
                      drawBorder: false,
                    },
                  },
                ],
              },
              layout: {
                padding: { left: 0, right: 0, top: 15, bottom: 15 },
              },
            }}
            />


                    {/* <Line
                      data={dashboardAllProductsChart.data}
                      options={dashboardAllProductsChart.options}
                    /> */}
                  </div>
                </CardBody>
                {/* <CardFooter>
                  <div className="stats">
                    <i className="now-ui-icons arrows-1_refresh-69" /> Just
                    Updated
                  </div>
                </CardFooter> */}
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Global Status</h5>
                  <CardTitle tag="h4" >Admission Total </CardTitle>
                  <CardTitle tag="h4" style={{fontWeight: "bold", fontSize: 24}}>
                    {user.admissionCount}
                    </CardTitle>
                   
                    {/* <UncontrolledDropdown>
                    <DropdownToggle
                      className="btn-round btn-outline-default btn-icon"
                      color="default"
                    >
                      <i className="now-ui-icons loader_gear" />
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem>Action</DropdownItem>
                      <DropdownItem>Another Action</DropdownItem>
                      <DropdownItem>Something else here</DropdownItem>
                      <DropdownItem className="text-danger">
                        Remove data
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown> */}

                </CardHeader>
                <CardBody>
                  <div className="chart-area">
                  <Bar  
            data={this.state.AdmissionData}  
            options={{
             
                maintainAspectRatio: false,
                legend: {
                  display: false,
                },
                tooltips: {
                  bodySpacing: 4,
                  mode: "nearest",
                  intersect: 0,
                  position: "nearest",
                  xPadding: 10,
                  yPadding: 10,
                  caretPadding: 10,
                },
                responsive: 1,
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        maxTicksLimit: 7,
                      },
                      gridLines: {
                        zeroLineColor: "transparent",
                        drawBorder: false,
                      },
                    },
                  ],
                  xAxes: [
                    {
                      display: 0,
                      ticks: {
                        display: false,
                      },
                      gridLines: {
                        zeroLineColor: "transparent",
                        drawTicks: false,
                        display: false,
                        drawBorder: false,
                      },
                    },
                  ],
                },
                layout: {
                  padding: { left: 0, right: 0, top: 15, bottom: 15 },
                },
              
            }}
            />
                    {/* <Bar
                      data={dashboard24HoursPerformanceChart.data}
                      options={dashboard24HoursPerformanceChart.options}
                    /> */}
                  </div>
                </CardBody>
                {/* <CardFooter>
                  <div className="stats">
                    <i className="now-ui-icons ui-2_time-alarm" /> Last 7 days
                  </div>
                </CardFooter> */}
              </Card>
            </Col>
          </Row>
          ))}
          <br/>
           <Row>
           <Col xs={12} md={5}>
              <Card>
                <CardHeader>
                  <h5 className="card-category">  {new Date().toLocaleString('en-US', { weekday: 'short',  month: 'short',  day: '2-digit',  year: 'numeric' }) + ''}</h5>
                  {/* <CardTitle tag="h4">Doctor's Summary</CardTitle> */}
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                    <tr>
            {this.generateHeadercolumnHeaderParticulars()}
            </tr>
                    </thead>
                    <tbody>
                    {this.generateTableDataParticulars()}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} md={7}>
              <Card>
                <CardHeader>
                  <h5 className="card-category">Doctor's Summary</h5>
                  {/* <CardTitle tag="h4">Doctor's Summary</CardTitle> */}
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                    <tr>
            {this.generateHeader()}
            </tr>
                    </thead>
                    <tbody>
                    {this.generateTableData()}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
         
           
         
          </Row> 
          <br/>


          <Row>
          <Col xs={12} md={5}>
              <Card>
                <CardHeader>
                  <h5 className="card-category">Day Wise Collection Report</h5>
                  {/* <CardTitle tag="h4">Doctor's Summary</CardTitle> */}
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                    <tr>
            {this.generateHeadercolumnDayWiseCollection()}
            </tr>
                    </thead>
                    <tbody>
                    {this.generateTableDataDayWiseCollection()}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} md={7}>
              <Card>
                <CardHeader>
                  <h5 className="card-category">Month Wise Collection Report</h5>
                  {/* <CardTitle tag="h4">Doctor's Summary</CardTitle> */}
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                    <tr>
            {this.generateHeadercolumnMonthWiseCollection()}
            </tr>
                    </thead>
                    <tbody>
                    {this.generateTableDataMonthWiseCollection()}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
         
          
         
          </Row> 
          {/* <Row>
            <Col xs={12} md={6}>
              <Card className="card-tasks">
                <CardHeader>
                  <h5 className="card-category">Backend Development</h5>
                  <CardTitle tag="h4">Tasks</CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="table-full-width table-responsive">
                    <Table>
                      <tbody>
                        <tr>
                          <td>
                            <FormGroup check>
                              <Label check>
                                <Input defaultChecked type="checkbox" />
                                <span className="form-check-sign" />
                              </Label>
                            </FormGroup>
                          </td>
                          <td className="text-left">
                            Sign contract for "What are conference organizers
                            afraid of?"
                          </td>
                          <td className="td-actions text-right">
                            <Button
                              className="btn-round btn-icon btn-icon-mini btn-neutral"
                              color="info"
                              id="tooltip731609871"
                              type="button"
                            >
                              <i className="now-ui-icons ui-2_settings-90" />
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip731609871"
                            >
                              Edit Task
                            </UncontrolledTooltip>
                            <Button
                              className="btn-round btn-icon btn-icon-mini btn-neutral"
                              color="danger"
                              id="tooltip923217206"
                              type="button"
                            >
                              <i className="now-ui-icons ui-1_simple-remove" />
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip923217206"
                            >
                              Remove
                            </UncontrolledTooltip>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <FormGroup check>
                              <Label check>
                                <Input type="checkbox" />
                                <span className="form-check-sign" />
                              </Label>
                            </FormGroup>
                          </td>
                          <td className="text-left">
                            Lines From Great Russian Literature? Or E-mails From
                            My Boss?
                          </td>
                          <td className="td-actions text-right">
                            <Button
                              className="btn-round btn-icon btn-icon-mini btn-neutral"
                              color="info"
                              id="tooltip907509347"
                              type="button"
                            >
                              <i className="now-ui-icons ui-2_settings-90" />
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip907509347"
                            >
                              Edit Task
                            </UncontrolledTooltip>
                            <Button
                              className="btn-round btn-icon btn-icon-mini btn-neutral"
                              color="danger"
                              id="tooltip496353037"
                              type="button"
                            >
                              <i className="now-ui-icons ui-1_simple-remove" />
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip496353037"
                            >
                              Remove
                            </UncontrolledTooltip>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <FormGroup check>
                              <Label check>
                                <Input defaultChecked type="checkbox" />
                                <span className="form-check-sign" />
                              </Label>
                            </FormGroup>
                          </td>
                          <td className="text-left">
                            Flooded: One year later, assessing what was lost and
                            what was found when a ravaging rain swept through
                            metro Detroit
                          </td>
                          <td className="td-actions text-right">
                            <Button
                              className="btn-round btn-icon btn-icon-mini btn-neutral"
                              color="info"
                              id="tooltip326247652"
                              type="button"
                            >
                              <i className="now-ui-icons ui-2_settings-90" />
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip326247652"
                            >
                              Edit Task
                            </UncontrolledTooltip>
                            <Button
                              className="btn-round btn-icon btn-icon-mini btn-neutral"
                              color="danger"
                              id="tooltip389516969"
                              type="button"
                            >
                              <i className="now-ui-icons ui-1_simple-remove" />
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip389516969"
                            >
                              Remove
                            </UncontrolledTooltip>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="now-ui-icons loader_refresh spin" /> Updated 3
                    minutes ago
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col xs={12} md={6}>
              <Card>
                <CardHeader>
                  <h5 className="card-category">All Persons List</h5>
                  <CardTitle tag="h4">Employees Stats</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th>Name</th>
                        <th>Country</th>
                        <th>City</th>
                        <th className="text-right">Salary</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Dakota Rice</td>
                        <td>Niger</td>
                        <td>Oud-Turnhout</td>
                        <td className="text-right">$36,738</td>
                      </tr>
                      <tr>
                        <td>Minerva Hooper</td>
                        <td>Curaçao</td>
                        <td>Sinaai-Waas</td>
                        <td className="text-right">$23,789</td>
                      </tr>
                      <tr>
                        <td>Sage Rodriguez</td>
                        <td>Netherlands</td>
                        <td>Baileux</td>
                        <td className="text-right">$56,142</td>
                      </tr>
                      <tr>
                        <td>Doris Greene</td>
                        <td>Malawi</td>
                        <td>Feldkirchen in Kärnten</td>
                        <td className="text-right">$63,542</td>
                      </tr>
                      <tr>
                        <td>Mason Porter</td>
                        <td>Chile</td>
                        <td>Gloucester</td>
                        <td className="text-right">$78,615</td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
        </div>
      </>
    );
  }
  
}


// export default Dashboard;
