import Login from './Login';
import Dashboard from "views/Dashboard.js";
import Notifications from "views/Notifications.js";
import Logout from "views/Logout.js";
import Icons from "views/Icons.js";
import Typography from "views/Typography.js";
import TableList from "views/TableList.js";
import Maps from "views/Maps.js";
import Upgrade from "views/Upgrade.js";
import UserPage from "views/UserPage.js";
import Registration from "views/Registration.js";
import RegistrationList from "views/RegistrationList.js";
import { getUser, removeUserSession } from './Utils/Common';


const user = getUser();

if(user ==null)
{
  //removeUserSession();
 // props.history.push('/');
 // location.reload('http://localhost:3000/');
}
else{
  //alert(user);
}

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "design_app",
    component: Dashboard,
    layout: "/admin",
  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "design_image",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "location_map-big",
  //   component: Maps,
  //   layout: "/admin",
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: "ui-1_bell-53",
  //   component: Notifications,
  //   layout: "/admin",
  // },
  // {
  //   path: "/user-page",
  //   name: "User Profile",
  //   icon: "users_single-02",
  //   component: UserPage,
  //   layout: "/admin",
  // },
  // {
  //   path: "/extended-tables",
  //   name: "Table List",
  //   icon: "files_paper",
  //   component: TableList,
  //   layout: "/admin",
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   icon: "design-2_ruler-pencil",
  //   component: Typography,
  //   layout: "/admin",
  // },
  // {
  //   pro: true,
  //   path: "/upgrade",
  //   name: "Upgrade to PRO",
  //   icon: "objects_spaceship",
  //   component: Upgrade,
  //   layout: "/admin",
  // },
  { 
    path: "/Registration",
    name: "Registration",
    icon: "users_single-02",
    component: Registration,
    layout: "/admin",
  },
  { 
    path: "/RegistrationList",
    name: "RegistrationList",
    icon: "users_single-02",
    component: RegistrationList,
    layout: "/admin",
  },
  {
    path: "/Logout",
    name: "Logout",
    icon: "users_single-02",
    component: Logout,
    layout: "/admin",
  },

];
export default dashRoutes;
