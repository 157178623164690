import React, { useState } from 'react';
import axios from 'axios';
import { apiUrl,  setUserSession } from './Utils/Common';
// import Dashboard from './Dashboard';
import './App.css';

// mandatory
import 'react-s-alert/dist/s-alert-default.css';

// optional - you can choose the effect you want
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import 'react-s-alert/dist/s-alert-css-effects/scale.css';
import 'react-s-alert/dist/s-alert-css-effects/bouncyflip.css';
import 'react-s-alert/dist/s-alert-css-effects/flip.css';
import 'react-s-alert/dist/s-alert-css-effects/genie.css';
import 'react-s-alert/dist/s-alert-css-effects/jelly.css';
import 'react-s-alert/dist/s-alert-css-effects/stackslide.css';

import ReactAudioPlayer from 'react-audio-player';

import Alert from 'react-s-alert';

import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';


function Login(props) {
   
  const [loading, setLoading] = useState(false);
  const UserId = useFormInput('');
  const password = useFormInput('');
  const [error, setError] = useState(null);

  const loginApi = apiUrl();

  // handle button click of login form
  const handleLogin = () => {
       debugger;
    setError(null);
    setLoading(true);
    var url = loginApi + 'Login';
    axios.post(url.trim(), { UserId: UserId.value, password: password.value })
 
    .then(response => {
      
      if(response.data.length == 0)
      {
          Alert.error('Incorrect Id/Pass!!!',
          {
          position: 'top-right',
          effect: 'slide',
          html: false,
          //offset: 150,
          beep:true,
          beep: 'audio_file.mp3',
          preserveContext: true,
          
      });
        //alert("INCORRECT ID\PASS!!!");
        return;
      }
  else{
      setLoading(false);
      setUserSession(response.data[0].token, response.data[0].user);
       
      //props.history.push('/Dashboard');
     // props.history.push('/admin/dashboard');
     
      props.history.push({ 
        pathname: '/admin/dashboard',
        state: 'dashboard'
       });
  }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
    // .catch(error => {
    //   setLoading(false);
    //   if (error.response.status === 401) setError(error.response.data.message);
    //   else setError("Something went wrong. Please try again later.");
    // });
  }

  return (
    <div class="row">
     <Alert beep={{error: 'audio_file.mp3'}} />
      {/* <Alert stack={{limit: 3}} /> */}
    <div class="col-md-6 col-xs-12 col-xl-6 mx-auto p-0 " >
        <div class="card">
            <div class="login-box">
                <div class="login-snip"> <br/>
                <input id="tab-1" type="radio" name="tab" class="sign-in" checked />
                  <label for="tab-1" class="tab">Login</label> 
                  
                  <input id="tab-2" type="radio" name="tab" class="sign-up" />
                    {/* <label for="tab-2" class="tab">Sign Up</label> */}
                    <label for="tab-2" class="tab"></label>
                    <div class="login-space">
                        <div class="login"><br/><br/>
                            <div class="group"> <label for="user" class="label">Username</label> 
                            <input id="user" type="text" {...UserId} class="input"  placeholder="Enter your username" /> </div>
                            <div class="group"> <label for="pass" class="label">Password</label> 
                            <input id="pass" type="password" {...password} class="input" data-type="password" placeholder="Enter your password" /> 
                            </div><br/><br/>
                            {/* <div class="group"> <input id="check" type="checkbox" class="check" checked />
                             <label for="check"><span class="icon"></span> Keep me Signed in</label> </div> */}
                            <div class="group"> <input type="submit" class="button" value="Sign In" onClick={handleLogin} /> </div>
                            <div class="hr"></div>
                            {/* <div class="foot"> <a href="#">Forgot Password?</a> </div> */}
                        </div>
                        {/* <div class="sign-up-form">
                            <div class="group"> <label for="user" class="label">Username</label> 
                            <input id="user" type="text" class="input" placeholder="Create your Username" /> </div>
                            <div class="group"> <label for="pass" class="label">Password</label> 
                            <input id="pass" type="password" class="input" data-type="password" placeholder="Create your password" /> </div>
                            <div class="group"> <label for="pass" class="label">Repeat Password</label> 
                            <input id="pass" type="password" class="input" data-type="password" placeholder="Repeat your password" /> </div>
                            <div class="group"> <label for="pass" class="label">Email Address</label>
                            <input id="pass" type="text" class="input" placeholder="Enter your email address" /> </div>
                            <div class="group"> <input type="submit" class="button" value="Sign Up" /> </div>
                            <div class="hr"></div>
                            <div class="foot"> <label for="tab-1">Already Member?</label> </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  );
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default Login;