import React from "react";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import { countApiUrl, getUser, removeUserSession } from '../Utils/Common';

import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import styled, { css } from 'styled-components'


// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

import axios from 'axios';
import { apiUrl, setUserSession } from '../Utils/Common';
import { Card, CardHeader, CardBody, CardFooter, CardTitle, Row, Col, UncontrolledDropdown, DropdownToggle,  DropdownMenu, DropdownItem, Table, Button, Label, FormGroup, Input, UncontrolledTooltip,} from "reactstrap";
const RegistrationListcolumnHeader =["RegNo","Date","Patient Name","City","Sate"];




import FixedPlugin from "components/FixedPlugin/FixedPlugin";


const RegistrationListApi = apiUrl();

class RegistrationList extends React.Component {
    constructor(props) {
      super(props);
      this.state = { RegistrationList: [] };
    }
    componentDidMount() { this.RegistrationList();this.RegistrationListHeader();this.RegistrationListTableData(); }
    RegistrationList = async () => {
      debugger;
      try { //try to get data
        var url = RegistrationListApi+'RegistrationList';
          const response = await fetch(url);
          if (response.ok) { // ckeck if status code is 200
            debugger;
              const data = await response.json();
              var a = data;
              this.setState({ RegistrationList: data});
            
          } else { this.setState({ error: true }) }
      } catch (e) { //code will jump here if there is a network problem
  this.setState({ error: true });
  }};

  RegistrationListHeader(){
    let res=[];
    for(var i =0; i < RegistrationListcolumnHeader.length; i++){
        res.push(<th key={RegistrationListcolumnHeader[i]}>{RegistrationListcolumnHeader[i]}</th>)
    }
    return res;
  }



  RegistrationListTableData(){
    let res=[];
        let tableData = this.state.RegistrationList;
        for(var i =0; i < tableData.length; i++){
            res.push(
            <tr>
            <td>{tableData[i].RegNo}</td>
            <td>{tableData[i].RegDate}</td>
            <td>{tableData[i].PatientFName}</td>
            <td>{tableData[i].City}</td>
            <td>{tableData[i].State}</td>
            </tr>
            )
        }     
        return res;
  }

  
    render() {
     
      return (
        <div className="content">

       <br/>
           <Row>
            <Col xs={12} md={7}>
              <Card>
                <CardHeader>
                  <h5 className="card-category">Registration List</h5>
                  {/* <CardTitle tag="h4">Doctor's Summary</CardTitle> */}
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                    <tr>
            {this.RegistrationListHeader()}
            </tr>
                    </thead>
                    <tbody>
                    {this.RegistrationListTableData()}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
         
           
          </Row> 


        </div>
      );
    }
  }
  export default RegistrationList