
// import React from "react";
// import ReactDOM from "react-dom";
// import { createBrowserHistory } from "history";
// import { Router, Route, Switch, Redirect } from "react-router-dom";

// import "bootstrap/dist/css/bootstrap.css";
// import "assets/scss/now-ui-dashboard.scss?v1.4.0";
// import "assets/css/demo.css";

// import AdminLayout from "layouts/Admin.js";

// const hist = createBrowserHistory();

// ReactDOM.render(
//   <Router history={hist}>
//     <Switch>
//       <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
//       <Redirect to="/admin/dashboard" />
//     </Switch>
//   </Router>,
//   document.getElementById("root")
// );
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { Route, Redirect } from 'react-router';



import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect,routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/now-ui-dashboard.scss?v1.4.0";
import "assets/css/demo.css";
import AdminLayout from "layouts/Admin.js";
import Login from "./Login.js";


const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      {/* <App /> */}
      <Route path="/" exact={true} name={Login}   render={(props) => <Login {...props} />} />

      {/* <Route exact  path="/" name="login" render={props => <Login {...props}/>} /> */}
      <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
      {/* <Redirect to="/Login" /> */}
    </Switch>
  </Router>,
  document.getElementById("root")
);


// ReactDOM.render(
//   <React.StrictMode>
//     <Router history={hist}>
//     <Switch>
//        <App />
//     </Switch>
//   </Router>,
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
