import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Login from './Login';
// import Dashboard from './Dashboard'
import Dashboard from "./views/Dashboard.js";



class App extends Component {
 render() {
    return (
      <Router>
        <div>
          <Route path='/' exact={true} name={Login}  component={Login} />
          <Route path="/admin/login" component={Dashboard} />  
          {/* <Route  path="/Dashboard/:id" component={Dashboard} /> */}
        </div>
      </Router>
    );
  }
}

export default App;